// src/hooks/useDocument.ts
import { useEffect, useState } from 'react';
import getDocumentPages from './getDocumentPages';

export default ({ url }: { url: string }) => {
  // console.log('url', url);
  const [ pages, setPages ] = useState<string[]>([]);
  useEffect(() => {
    const getPages = async () => {
      const canvases = await getDocumentPages({
        url
      });
      setPages(canvases);
    };

    if (url.startsWith('data:application/pdf')) {
      getPages();
    } else {
      // non pdf
      setPages([ url ]);
    }
  }, [ url ]);

  return {
    pages
  };
};
