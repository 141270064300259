import './App.css';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip } from 'antd';
import Konva from 'konva';
import React from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { useScreenshot } from 'use-react-screenshot';

import { AppContext, ColourGroup, ToolMode } from './AppContext';
import { BlueprintImage } from './BlueprintImage';
import { CanvasControls, StageScaleType } from './CanvasControls';
import { themeColor } from './colours';
import { AREA_ID_PREFIX, areaFromCoords, calcLineLength, calcRotation, getPolygonCentroid } from './lineUtils';
import { SidePanel } from './SidePanel';
import useDocument from './useDocument';


const pdfPanelWidth = 150;

// TODO: create an AppWrapper and put the update context definitions there
export const App = () => {
  const menuNode = document.getElementById('menu');

  const updateContext = (prop: string, update: any) => setContext(context => ({ ...context, [ prop ]: update }));
  const [ context, setContext ] = React.useState({
    selectedShape: null,
    setSelectedShape: (update: any) => updateContext('selectedShape', update),
    calLine: {} as any,
    setCalLine: (update: any) => updateContext('calLine', update),
    // For zoom in/out
    stageScale: 1,
    setStageScale: (update: number) => updateContext('stageScale', update),
    toolMode: ToolMode.OBSERVE,
    setToolMode: (update: ToolMode) => updateContext('toolMode', update)
  });

  //http://www.africau.edu/images/default/sample.pdf
  const [ currentPage, setCurrentPage ] = React.useState(0);
  const [ image, setImage ] = React.useState('/images/empty.png');//'/images/111.pdf');//'/images/apartment.png');
  const [ width, setWidth ] = React.useState(window.innerWidth - 10 - pdfPanelWidth); // 10px for right borders
  const [ height, setHeight ] = React.useState(window.innerHeight - 50); // 40px for top bar, 10px for bottom border
  const [ callibrate, setCallibrate ] = React.useState(0);

  // Scale
  // const [ scale, setScale ] = React.useState(1);
  // this is the scale of pixels per metre
  const scale = React.useRef<any>(1);

  const stage = React.useRef<any>();
  const layerRef = React.useRef<any>();
  const selectorRef = React.useRef<any>();
  const toolMode = React.useRef<ToolMode>(ToolMode.OBSERVE); // to change the mouse cursor.

  const [ bufferedLine, setBufferedLine ] = React.useState<any>(null);
  const [ areaId, setAreaId ] = React.useState<any>(null);

  const [ toggleRefresh, setToggleRefresh ] = React.useState<boolean>(false);

  // for side panel
  // see: https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const [ visible, _setVisible ] = React.useState<boolean>(true);
  const visibleRef = React.useRef<boolean>(visible);
  const setVisible = (data: boolean) => {
    visibleRef.current = data;
    _setVisible(data);
  };

  const [ helpVisible, setHelpVisible ] = React.useState<boolean>(false);

  // TODO: when changing colour group, always go back to observe mode!!
  const [ colourGroup, setColourGroup ] = React.useState<ColourGroup>(ColourGroup.RED);

  const [ lines, setLines ] = React.useState<any[]>([]);
  const [ areaPoints, setAreaPoints ] = React.useState<any>({});
  const [ count, setCount ] = React.useState<any>({
    red: 0,
    orange: 0,
    green: 0,
    blue: 0
  });

  const [ selected, setSelected ] = React.useState<any>(null);

  const areaGroupRef = React.useRef<any>();

  // e.g.
  // red: { dis: { asdf: 12, qwer: 13 }}
  // this represents the current state of entities drawn on the blueprint
  const groupsRef = React.useRef<any>({
    red: {
      sa: {},
      dis: {},
      count: 0
    },
    orange: {
      sa: {},
      dis: {},
      count: 0
    },
    green: {
      sa: {},
      dis: {},
      count: 0
    },
    blue: {
      sa: {},
      dis: {},
      count: 0
    }
  });

  const screenshotRef = React.useRef<any>();
  const [ screenshotImage, takeScreenshot ] = useScreenshot();
  const getScreenshot = () => takeScreenshot(screenshotRef.current);

  const { pages } = useDocument({
    url: image // https://pdfjs-express.s3-us-west-2.amazonaws.com/docs/choosing-a-pdf-viewer.pdf'
    // url: '/images/111.pdf'
  });

  const clearLines = (id?: string) => {
    // assume second value is the group
    const selectedColourGroup = id ? id.split('-')[ 1 ] : colourGroup.toLocaleLowerCase();

    const filteredDis = groupsRef.current[ selectedColourGroup ].dis;
    if (id) {
      delete filteredDis[ id ];
    }
    const updatedLines = id ?
      lines.filter(l => l.attrs.id.startsWith('line') && l.attrs.id !== id) :
      lines.filter(l => !l.attrs.id.startsWith(`line-${selectedColourGroup}`));

    // console.log('updatedLines', lines, updatedLines);
    groupsRef.current = {
      ...groupsRef.current,
      [ selectedColourGroup ]: {
        ...(groupsRef.current[ selectedColourGroup ]),
        dis: id ? filteredDis : {}
      }
    };
    setLines(updatedLines);
  };

  const clearArea = (id?: string) => {
    const selectedColourGroup = id ? id.split('-')[ 1 ] : colourGroup.toLocaleLowerCase();

    const filteredSa = groupsRef.current[ selectedColourGroup ].sa;
    if (id) {
      delete filteredSa[ id ];
    }
    const updatedAreas = Object.keys(areaPoints).reduce((acc, curr) => {
      if (id) {
        return curr === id ? acc : { ...acc, [ curr ]: areaPoints[ curr ] };
      }

      return curr.startsWith(`${AREA_ID_PREFIX}-${selectedColourGroup}`) ? acc : { ...acc, [ curr ]: areaPoints[ curr ] };
    }, {});

    groupsRef.current = {
      ...groupsRef.current,
      [ selectedColourGroup ]: {
        ...(groupsRef.current[ selectedColourGroup ]),
        sa: id ? filteredSa : {}
      }
    };
    setAreaPoints(updatedAreas);
  };

  const clearCount = () => {
    const selectedColourGroup = colourGroup.toLocaleLowerCase();
    groupsRef.current = {
      ...groupsRef.current,
      [ selectedColourGroup ]: {
        ...(groupsRef.current[ selectedColourGroup ]),
        count: 0
      }
    };
    setCount({
      ...count,
      [ selectedColourGroup ]: 0
    });
  };

  // TODO: add type perimeter/area/count in the future
  // as an additional argument
  const clearAnnotations = (all = true) => {
    groupsRef.current = {
      red: {
        sa: {},
        dis: {},
        count: 0
      },
      orange: {
        sa: {},
        dis: {},
        count: 0
      },
      green: {
        sa: {},
        dis: {},
        count: 0
      },
      blue: {
        sa: {},
        dis: {},
        count: 0
      }
    };

    setLines([]);
    setAreaPoints({});
    setCount({
      red: 0,
      orange: 0,
      green: 0,
      blue: 0
    });
  };

  const getScaledPosition = (pos: { x: any, y: any }) => {
    const stageAttrs = stage.current?.attrs;
    const x = (pos.x - (stageAttrs.x ?? 0)) / stageAttrs.scaleX;
    const y = (pos.y - (stageAttrs.y ?? 0)) / stageAttrs.scaleY;
    return { x: x, y: y };
  };

  /*
  * Returns the position pointer relative to the scaled and or dragged
  * stage. You must set the stage options x,y,scaleX, and scaleY.  If you
  * don't set them then you will need to adjust the stageAttrs to just stage
  * and change the calculation for x & y as they require those attributes.
  */
  const getScaledPointerPosition = () => {
    const pointerPosition = stage.current?.getPointerPosition();
    const stageAttrs = stage.current?.attrs;
    const x = (pointerPosition.x - (stageAttrs.x ?? 0)) / stageAttrs.scaleX;
    const y = (pointerPosition.y - (stageAttrs.y ?? 0)) / stageAttrs.scaleY;
    return { x: x, y: y };
  };

  const changeCursor = (mode: ToolMode, isPointer?: boolean) => {
    if (mode === ToolMode.OBSERVE) {
      stage.current.container().style.cursor = isPointer ? 'pointer' : 'move';
    } else {
      stage.current.container().style.cursor = 'crosshair';
    }
  };

  const showHighlightRect = (show: boolean, group?: any) => {
    selectorRef.current?.visible(show);
    if (show) {
      const clientRect = group.getClientRect();
      const scaledPos = getScaledPosition({ x: clientRect.x, y: clientRect.y });
      selectorRef.current?.setAttrs({ ...scaledPos, width: clientRect.width / stage.current?.attrs.scaleX, height: clientRect.height / stage.current?.attrs.scaleY });
    }
    layerRef.current?.draw();
  };

  const fitStageIntoParentContainer = () => {
    const container = document.querySelector('#root') as any;

    // now we need to fit stage into parent
    // const containerWidth = container?.offsetWidth;
    // console.log('containerWidth', containerWidth);
    // to do this we need to scale the stage
    // const scale = containerWidth / stageWidth;

    stage.current?.width(container?.offsetWidth - 10 - pdfPanelWidth);
    stage.current?.height(container?.offsetHeight - 50);
    // stage.height(stageHeight * scale);
    // stage.scale({ x: scale, y: scale });
    stage.current?.draw();
  };
  // adapt the stage on any window resize
  window.addEventListener('resize', fitStageIntoParentContainer);

  const deleteBufferedLine = () => {
    // Reset bufferedLine
    // remove last bufferedLine
    const deleteLine = layerRef.current?.findOne(`#${bufferedLine?.id}-group`);
    deleteLine?.destroy();
    setBufferedLine(null);
  };

  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key.toUpperCase() === 'ESCAPE') { // escape
        context.setToolMode(ToolMode.OBSERVE);
        toolMode.current = ToolMode.OBSERVE;

        deleteBufferedLine();

        // update lines to update Side Panel
        setLines(layerRef.current?.find('Line').filter((line: any) => validLineFilter(line.attrs.id)));

        changeCursor(ToolMode.OBSERVE);
      } else if (event.key.toUpperCase() === 'DELETE') { // delete
        // TODO: delete doesn't work when you click on the label.
        // NOTE: We don't want to do this for count
        // as it's annoying to recalculate the count and shift the counts
        // At this point, they should clear the entire colour group and redo it again.

        const annotation = layerRef.current?.findOne(`#${selected}`);

        // Disallow deleting the calibration line
        if (annotation.attrs.id === 'calibration') {
          return;
        }

        annotation?.parent.destroy();
        // layerRef.current?.batchDraw();

        setSelected(null);
        context.setSelectedShape(null);

        if (selected?.startsWith(AREA_ID_PREFIX)) {
          // get rid of selected area
          // const areas = areaPoints;
          // delete areas[ selected ];
          // setAreaPoints(areas);
          clearArea(selected);
        } else if (selected?.startsWith('line')) {
          // For some reason, setLines refreshes the SidePanel.
          // ensure setAreaPoints happens before this line!
          // This is because areaPoints is an object?
          clearLines(selected);
        }

        // setLines(layerRef.current?.find('Line').filter((line: any) => !line.attrs.id.startsWith(AREA_ID_PREFIX)));

        showHighlightRect(false);
      } else if (event.key.toUpperCase() === '`') {
        setVisible(!visibleRef.current);
      }

      layerRef.current?.batchDraw();
    };
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [ selected, bufferedLine ]);

  // When the scale changes from side panel, redraw everything
  React.useEffect(() => {
    const drawnLines = layerRef.current?.find('Line');//.filter((line: any) => line.attrs.id !== 'calibration');
    drawnLines.forEach((line: any) => {
      const lineLabel = line.parent.findOne('Text');
      //console.log('lineLabel', lineLabel);
      const { points } = line.attrs;
      const length = calcLineLength({ x: points[ 0 ], y: points[ 1 ] }, { x: points[ 2 ], y: points[ 3 ] });
      if (line.attrs.id === 'calibration') {
        lineLabel.text(`CAL: ${(length / scale.current).toFixed(3)}m`);
      } else {
        lineLabel.text(`${(length / scale.current).toFixed(3)}m`);
      }
      // line.parent.destroy()
    });

    Object.keys(areaPoints).forEach(id => {
      const area = areaPoints[ id ];
      const lineLabel = layerRef.current?.findOne(`#${id}-label`);
      lineLabel?.getText().text(`A: ${areaFromCoords(area, scale.current)}m²`);
    });

    layerRef.current?.batchDraw();

  }, [ toggleRefresh ]);

  React.useEffect(() => {
    const redGroup = new Konva.Group({ id: 'red-group', draggable: true });
    const orangeGroup = new Konva.Group({ id: 'orange-group', draggable: true });
    const greenGroup = new Konva.Group({ id: 'green-group', draggable: true });
    const blueGroup = new Konva.Group({ id: 'blue-group', draggable: true });

    // add groups
    layerRef.current?.add(redGroup);
    layerRef.current?.add(orangeGroup);
    layerRef.current?.add(greenGroup);
    layerRef.current?.add(blueGroup);

    layerRef.current?.batchDraw();
  }, []);

  const handleDrawLine = (lineToDraw: any) => {
    const lastClick = getScaledPointerPosition();

    // Last click is defined, so we can actually start drawing lines.
    const customLineId = `line-${colourGroup.toLocaleLowerCase()}-${Date.now()}`;
    const line = lineToDraw ?? {
      stroke: colourGroup.toLocaleLowerCase(),
      strokeWidth: 1,
      hitStrokeWidth: 30, // click area
      globalCompositeOperation: 'source-over',
      points: [ lastClick.x, lastClick.y ],
      id: customLineId
    };

    // normal line
    if (!lineToDraw) {
      // STEP 2: we have pointA of the line, let's draw up until where the mouse currently is.
      const group = new Konva.Group({ id: `${customLineId}-group`, draggable: true });

      // create label
      const label = new Konva.Label({
        x: lastClick.x,
        y: lastClick.y,
        id: `${customLineId}-label`,
        visible: false
      });

      //console.log('label', label);
      // add a tag to the label
      label.add(new Konva.Tag({
        fill: 'black',
        pointerDirection: 'down'
      }));

      // add text to the label
      label.add(new Konva.Text({
        text: '0m',
        fontFamily: 'Calibri',
        fontSize: 12,
        padding: 1,
        fill: 'white'
      }));

      const drawnLine = new Konva.Line(line);
      group.add(label);
      group.add(drawnLine);

      const anchor1 = new Konva.Circle({
        x: drawnLine.points()[ 0 ],
        y: drawnLine.points()[ 1 ],
        radius: 5,
        stroke: colourGroup.toLocaleLowerCase(),
        strokeWidth: 1,
        draggable: true,
        id: `${customLineId}-anchor1`
      });

      // draw both anchors in the same spot initially
      const anchor2 = new Konva.Circle({
        // x: drawnLine.points()[ 2 ],
        // y: drawnLine.points()[ 3 ],
        x: drawnLine.points()[ 0 ],
        y: drawnLine.points()[ 1 ],
        radius: 5,
        stroke: colourGroup.toLocaleLowerCase(),
        strokeWidth: 1,
        draggable: true,
        id: `${customLineId}-anchor2`
      });

      const handleDragEnd = () => {
        showHighlightRect(true, group);
        // Purpose is to update the Side Panel values
        groupsRef.current = {
          ...groupsRef.current,
          [ colourGroup.toLocaleLowerCase() ]: {
            ...(groupsRef.current[ colourGroup.toLocaleLowerCase() ]),
            dis: {
              ...(groupsRef.current[ colourGroup.toLocaleLowerCase() ].dis),
              [ customLineId ]: drawnLine.points()
            }
          }
        };
        setLines(layerRef.current?.find('Line').filter((line: any) => validLineFilter(line.attrs.id)));

        // need to rerender the side panel
        // setToggleRefresh(!toggleRefresh);
      };

      const updateLine = () => {
        const abs1 = anchor1.absolutePosition();
        const abs2 = anchor2.absolutePosition();

        // We need to divide if you are making the line zoomed in/out
        // Need to use stage reference so we can get the latest changes to the stageScale
        const points = [
          abs1.x / stage.current?.attrs.scaleX,
          abs1.y / stage.current?.attrs.scaleX,
          abs2.x / stage.current?.attrs.scaleX,
          abs2.y / stage.current?.attrs.scaleX,
        ];

        // Reset the line's absolute position
        drawnLine.absolutePosition({
          x: 0,
          y: 0
        });
        drawnLine.points(points);

        const lineLabel = layerRef.current?.findOne(`#${line.id}-label`);
        const rotation = calcRotation({ x: points[ 0 ], y: points[ 1 ] }, { x: points[ 2 ], y: points[ 3 ] });
        const length = calcLineLength({ x: points[ 0 ], y: points[ 1 ] }, { x: points[ 2 ], y: points[ 3 ] });
        lineLabel?.absolutePosition({
          x: abs1.x + (Math.cos(rotation * Math.PI / 180) * length * stage.current?.attrs.scaleX / 2),
          y: abs1.y + (Math.sin(rotation * Math.PI / 180) * length * stage.current?.attrs.scaleX / 2)
        });
        lineLabel?.rotation(rotation);
        lineLabel?.getText().text(`${(length / scale.current).toFixed(3)}m`);
      };

      anchor1.on('dragmove', updateLine);
      anchor1.on('dragend', handleDragEnd);
      anchor2.on('dragmove', updateLine);
      anchor2.on('dragend', handleDragEnd);
      group.add(anchor1);
      group.add(anchor2);
      group.on('mouseenter', () => {
        if (!stage.current) {
          return;
        }

        changeCursor(toolMode.current, true);
      });
      group.on('mouseleave', () => {
        if (!stage.current) {
          return;
        }

        changeCursor(toolMode.current);
      });
      group.on('click', () => {
        if (toolMode.current === ToolMode.OBSERVE) {
          showHighlightRect(true, group);
        }
      });
      group.on('dragmove', () => {
        showHighlightRect(true, group);
      });

      layerRef.current?.add(group);
    }

    layerRef.current?.batchDraw();

    // We have a line currently being drawn but we haven't set it down yet.
    setBufferedLine(line);
  };

  const handleDrawCal = () => {
    const lastClick = getScaledPointerPosition();

    const calibrationId = 'calibration';
    const calibrationColour = 'darkgreen';
    const calibrationLine = {
      stroke: calibrationColour,
      strokeWidth: 2,
      hitStrokeWidth: 30, // click area
      // globalCompositeOperation: 'source-over',
      points: [ lastClick.x, lastClick.y ],
      id: calibrationId
    };

    // normal line
    if (!context.calLine.id) {
      // STEP 2: we have pointA of the line, let's draw up until where the mouse currently is.
      const group = new Konva.Group({ id: `${calibrationId}-group`, draggable: true });

      // create label
      const label = new Konva.Label({
        x: lastClick.x,
        y: lastClick.y,
        id: `${calibrationId}-label`,
        visible: false
      });

      //console.log('label', label);
      // add a tag to the label
      label.add(new Konva.Tag({
        fill: 'black',
        pointerDirection: 'down'
      }));

      // add text to the label
      label.add(new Konva.Text({
        text: '0m',
        fontFamily: 'Calibri',
        fontSize: 12,
        padding: 1,
        fill: 'white'
      }));

      const drawnLine = new Konva.Line(calibrationLine);
      group.add(label);
      group.add(drawnLine);

      const anchor1 = new Konva.Circle({
        x: drawnLine.points()[ 0 ],
        y: drawnLine.points()[ 1 ],
        radius: 5,
        stroke: calibrationColour,
        strokeWidth: 1,
        draggable: true,
        id: `${calibrationId}-anchor1`
      });

      // draw both anchors in the same spot initially
      const anchor2 = new Konva.Circle({
        // x: drawnLine.points()[ 2 ],
        // y: drawnLine.points()[ 3 ],
        x: drawnLine.points()[ 0 ],
        y: drawnLine.points()[ 1 ],
        radius: 5,
        stroke: calibrationColour,
        strokeWidth: 1,
        draggable: true,
        id: `${calibrationId}-anchor2`
      });

      const handleDragEnd = () => {
        showHighlightRect(true, group);
      };

      const updateLine = () => {
        const abs1 = anchor1.absolutePosition();
        const abs2 = anchor2.absolutePosition();

        // We need to divide if you are making the line zoomed in/out
        // Need to use stage reference so we can get the latest changes to the stageScale
        const points = [
          abs1.x / stage.current?.attrs.scaleX,
          abs1.y / stage.current?.attrs.scaleX,
          abs2.x / stage.current?.attrs.scaleX,
          abs2.y / stage.current?.attrs.scaleX,
        ];

        // Reset the line's absolute position
        drawnLine.absolutePosition({
          x: 0,
          y: 0
        });
        drawnLine.points(points);

        const lineLabel = layerRef.current?.findOne(`#${calibrationId}-label`);
        const rotation = calcRotation({ x: points[ 0 ], y: points[ 1 ] }, { x: points[ 2 ], y: points[ 3 ] });
        const length = calcLineLength({ x: points[ 0 ], y: points[ 1 ] }, { x: points[ 2 ], y: points[ 3 ] });
        lineLabel?.absolutePosition({
          x: abs1.x + (Math.cos(rotation * Math.PI / 180) * length * stage.current?.attrs.scaleX / 2),
          y: abs1.y + (Math.sin(rotation * Math.PI / 180) * length * stage.current?.attrs.scaleX / 2)
        });
        lineLabel?.rotation(rotation);
        lineLabel?.getText().text(`CAL: ${scale.current > 0 ? `${(length / scale.current).toFixed(3)}m` : '<ENTER SCALE>'}`);

        setCallibrate(length);
      };

      anchor1.on('dragmove', updateLine);
      anchor1.on('dragend', handleDragEnd);
      anchor2.on('dragmove', updateLine);
      anchor2.on('dragend', handleDragEnd);
      group.add(anchor1);
      group.add(anchor2);
      group.on('mouseenter', () => {
        if (!stage.current) {
          return;
        }

        changeCursor(toolMode.current, true);
      });
      group.on('mouseleave', () => {
        if (!stage.current) {
          return;
        }

        changeCursor(toolMode.current);
      });
      group.on('click', () => {
        if (toolMode.current === ToolMode.OBSERVE) {
          showHighlightRect(true, group);
        }
      });
      group.on('dragmove', () => {
        showHighlightRect(true, group);
      });

      layerRef.current?.add(group);
    }

    layerRef.current?.batchDraw();

    // We have a line currently being drawn but we haven't set it down yet.
    // setBufferedLine(line);
    context.setCalLine(calibrationLine);
  };

  const validLineFilter = (id: string) => !id.startsWith(AREA_ID_PREFIX) && !id.startsWith('calibration');

  const checkDeselect = (e: any) => {
    if (menuNode) {
      menuNode.style.display = 'none';
    }

    // deselect when clicked on empty area
    if (context.toolMode === ToolMode.OBSERVE) {
      // select the shape!
      // NOTE: assumed that calibration is the only rectangle in the canvas!
      // const calibrationSelected = e.target.className === 'Rect' ? 'callbration' : null;
      if (!e.target.className || e.target.className === 'Image') {
        setSelected(null);
        context.setSelectedShape(null);
        showHighlightRect(false);
        if (menuNode) {
          menuNode.style.display = 'none';
        }
      } else {
        if (e.target.className !== 'Rect') {
          // everything else
          setSelected(e.target.attrs.id);
          context.setSelectedShape(e.target.attrs.id);
        }
      }

    } else if (context.toolMode === ToolMode.AREA) {
      const pos = getScaledPointerPosition();
      const width = 4;
      const x = pos.x - width / 2;
      const y = pos.y - width / 2;

      // area of id
      const id = areaId ?? `${AREA_ID_PREFIX}-${colourGroup.toLocaleLowerCase()}`;
      const lineRef = layerRef.current?.findOne(`#${id}`);

      if (!lineRef) {
        const group = new Konva.Group({ id: `${id}-group`, draggable: true });
        const line = new Konva.Line({
          points: [ pos.x, pos.y ],
          // offsetY: 3,
          stroke: 'grey',
          strokeWidth: 1,
          closed: true,
          fill: colourGroup.toLocaleLowerCase(),//'rgba(0,255,0,0.5)',
          opacity: 0.75,
          id
        });

        // create label
        const label = new Konva.Label({
          x: pos.x,
          y: pos.y - 5,
          rotation: 0,
          id: `${id}-label`
        });

        // add a tag to the label
        label.add(new Konva.Tag({
          fill: 'black',
          pointerDirection: 'down'
        }));

        // add text to the label
        label.add(new Konva.Text({
          text: 'A: 0m²',
          fontFamily: 'Calibri',
          fontSize: 12,
          padding: 1,
          fill: 'white'
        }));

        const areaAnchor = new Konva.Rect({
          x: x,
          y: y,
          width: width,
          height: width,
          fill: 'grey',
          stroke: 'grey',
          strokeWidth: 1,
          draggable: false,
        });

        group.add(line);
        group.add(areaAnchor);
        group.add(label);
        group.on('mouseenter', () => {
          if (!stage.current) {
            return;
          }

          changeCursor(toolMode.current, true);
        });
        group.on('mouseleave', () => {
          if (!stage.current) {
            return;
          }

          changeCursor(toolMode.current);
        });
        group.on('click', () => {
          if (toolMode.current === ToolMode.OBSERVE) {
            showHighlightRect(true, group);
          }
        });
        group.on('dragmove', () => {
          showHighlightRect(true, group);
        });

        // addToColourGroup(group);
        layerRef.current?.add(group);
      } else {
        // The area is defined
        lineRef.points([
          ...lineRef.points(),
          pos.x,
          pos.y
        ]);

        const points = lineRef.points();
        groupsRef.current = {
          ...groupsRef.current,
          [ colourGroup.toLocaleLowerCase() ]: {
            ...(groupsRef.current[ colourGroup.toLocaleLowerCase() ]),
            sa: {
              ...(groupsRef.current[ colourGroup.toLocaleLowerCase() ].sa),
              [ id ]: points
            }
          }
        };
        setAreaPoints({ ...areaPoints, [ id ]: points });

        const lineLabel = layerRef.current?.findOne(`#${id}-label`);

        const areaCentre = getPolygonCentroid(points);
        const fallbackPosition = {
          x: points[ 0 ] + (points[ 2 ] ? (points[ 2 ] - points[ 0 ]) / 2 : 0),
          y: points[ 1 ] - 5
        };
        lineLabel?.position(areaCentre ?? fallbackPosition);
        lineLabel?.getText().text(`A: ${areaFromCoords(points, scale.current)}m²`);

        // Add anchor last
        const areaAnchor = new Konva.Rect({
          x: x,
          y: y,
          width: width,
          height: width,
          fill: 'grey',
          stroke: 'grey',
          strokeWidth: 1,
          draggable: false,
        });
        const areaGroup = layerRef.current?.findOne(`#${id}-group`);
        areaGroup.add(areaAnchor);
      }

      layerRef.current?.batchDraw();

    } else if (context.toolMode === ToolMode.COUNT) {
      // increment counter
      // updating the ref must be before the setCount!
      const selectedColourGroup = colourGroup.toLocaleLowerCase();
      const newValue = groupsRef.current[ selectedColourGroup ].count + 1;
      groupsRef.current = {
        ...groupsRef.current,
        [ selectedColourGroup ]: {
          ...(groupsRef.current[ selectedColourGroup ]),
          count: newValue
        }
      };
      setCount({
        ...count,
        [ selectedColourGroup ]: newValue
      });

      const pos = getScaledPointerPosition();

      // add label to layer:
      // TODO: create a service to create labels...
      // create label
      const label = new Konva.Label({
        x: pos.x,
        y: pos.y + 10,
        rotation: 0,
        id: `custom-count-label-${selectedColourGroup.toLocaleLowerCase()}-${newValue}`,
        draggable: true
      });

      // add a tag to the label
      label.add(new Konva.Tag({
        fill: selectedColourGroup,
        pointerDirection: 'down'
      }));

      // add text to the label
      label.add(new Konva.Text({
        // NOTE: Don't allow to delete count as we need to recalculate everything!!
        // id: `custom-count-text-${selectedColourGroup.toLocaleLowerCase()}-${newValue}`,
        text: `${newValue}`,
        fontFamily: 'Calibri',
        fontSize: 15,
        width: 20,
        height: 20,
        align: 'center',
        verticalAlign: 'middle',
        fontStyle: 'bold',
        padding: 1,
        fill: 'white'
      }));

      layerRef.current?.add(label);
      layerRef.current?.batchDraw();

    } else {
      if (context.toolMode === ToolMode.CAL && context.calLine.points?.length !== 4) {
        if (!context.calLine.id) {
          handleDrawCal();
        } else {
          // finish drawing the cal line...
          const pos = getScaledPointerPosition();

          const calibrationId = 'calibration';
          const lineGroup = layerRef.current?.findOne(`#${calibrationId}-group`);
          const drawnLine = lineGroup.findOne(`#${calibrationId}`);
          const lineLabel = lineGroup.findOne(`#${calibrationId}-label`);
          const anchor1 = lineGroup.findOne(`#${calibrationId}-anchor1`);
          const anchor2 = lineGroup.findOne(`#${calibrationId}-anchor2`);

          const lastClick = { x: drawnLine.points()[ 0 ], y: drawnLine.points()[ 1 ] };
          const lineLength = calcLineLength(lastClick, pos);
          const rotation = calcRotation(lastClick, pos);

          // cos A = adj/hyp
          // adj = cos A * hyp
          lineLabel?.visible(true);
          lineLabel?.position({
            x: lastClick.x + (Math.cos(rotation * Math.PI / 180) * lineLength / 2),
            y: lastClick.y + (Math.sin(rotation * Math.PI / 180) * lineLength / 2)
          });
          lineLabel?.rotation(rotation);
          lineLabel?.getText().text(`CAL: ${scale.current > 0 ? `${(lineLength / scale.current).toFixed(3)}m` : '<ENTER SCALE>'}`);

          drawnLine?.points([
            lastClick.x,
            lastClick.y,
            pos.x,
            pos.y
          ]);

          // REQUIRED to include offset
          anchor1.x(lastClick.x);
          anchor1.y(lastClick.y);
          anchor2.x(pos.x);
          anchor2.y(pos.y);

          layerRef.current?.batchDraw();

          // go back to observe
          context.setToolMode(ToolMode.OBSERVE);
          toolMode.current = ToolMode.OBSERVE;

          context.setCalLine({ ...context.calLine, points: [ lastClick.x, lastClick.y, pos.x, pos.y ] });
          setCallibrate(lineLength);
        }

        return;
      }

      // must be perimeter mode
      // create line when we are in 'perimeter' mode
      setSelected(null);
      context.setSelectedShape(null);

      if (bufferedLine) {
        // STEP 4: We have an existing last click, which means we are in the second click
        // and the user wants to draw the finished line.
        const pos = getScaledPointerPosition(); //stage.current?.getPointerPosition();

        const lineGroup = layerRef.current?.findOne(`#${bufferedLine.id}-group`);
        const drawnLine = lineGroup.findOne(`#${bufferedLine.id}`);
        const lineLabel = lineGroup.findOne(`#${bufferedLine.id}-label`);
        const anchor1 = lineGroup.findOne(`#${bufferedLine.id}-anchor1`);
        const anchor2 = lineGroup.findOne(`#${bufferedLine.id}-anchor2`);

        const lastClick = { x: drawnLine.points()[ 0 ], y: drawnLine.points()[ 1 ] };
        const lineLength = calcLineLength(lastClick, pos);
        const rotation = calcRotation(lastClick, pos);

        // cos A = adj/hyp
        // adj = cos A * hyp
        lineLabel?.visible(true);
        lineLabel?.position({
          x: lastClick.x + (Math.cos(rotation * Math.PI / 180) * lineLength / 2),
          y: lastClick.y + (Math.sin(rotation * Math.PI / 180) * lineLength / 2)
        });
        lineLabel?.rotation(rotation);
        lineLabel?.getText().text(`${(lineLength / scale.current).toFixed(3)}m`);

        // //console.log('drawnLine', lastClick!.x, lastClick!.y, pos.x, pos.y);
        // drawnLine?.absolutePosition({
        //   x: 0,
        //   y: 0
        // });

        drawnLine?.points([
          lastClick.x,
          lastClick.y,
          pos.x,
          pos.y
        ]);

        // REQUIRED to include offset
        anchor1.x(lastClick.x);
        anchor1.y(lastClick.y);
        anchor2.x(pos.x);
        anchor2.y(pos.y);

        layerRef.current?.batchDraw();

        // Reset bufferedLine
        // setBufferedLine(null);

        // update lines to update Side Panel
        groupsRef.current = {
          ...groupsRef.current,
          [ colourGroup.toLocaleLowerCase() ]: {
            ...(groupsRef.current[ colourGroup.toLocaleLowerCase() ]),
            dis: {
              ...(groupsRef.current[ colourGroup.toLocaleLowerCase() ].dis),
              [ bufferedLine.id ]: drawnLine.points()
            }
          }
        };
        setLines(layerRef.current?.find('Line').filter((line: any) => validLineFilter(line.attrs.id)));

        // STEP 1/2/3
        handleDrawLine(null);
      } else {
        // STEP 1/2/3
        handleDrawLine(bufferedLine);
      }

      // STEP 1/5: start of the first OR a subsequent line..
      // if we're in context menu, remove the last click
      // which === 1 means it was a left-click event, 3 === right-click event
      // setLastClick(e.evt.which === 1 ? stage.current?.getPointerPosition() : null);
    }
  };

  const handleStageScale = (type: StageScaleType) => {
    if (type === StageScaleType.RESET) {
      context.setStageScale(1);
    } else if (type === StageScaleType.ZOOM_IN) {
      context.setStageScale(context.stageScale + 0.1);
    } else {
      context.setStageScale(context.stageScale - 0.1);
    }
  };

  const handleDragMovePoint = (event: any, id: number) => {
    // console.log('iasdfasdfasdfd', id);
    const anchorRef = areaGroupRef.current?.findOne(`#area-anchor-${id}`);

    // const points = this.state.points;
    // const index = event.target.index - 1; // 3 - 1 = 2 <-- second point,
    // // console.log(event.target);
    // const pos = [ event.target.attrs.x, event.target.attrs.y ];
    // // console.log('move', event);
    // // console.log(pos);
    // // Remove x,y coords, replace with the new coords
    // setPoints([ ...points.slice(0, (index - 1) * 2), pos[ 0 ], pos[ 1 ], ...points.slice(index * 2) ]);

    anchorRef.absolutePosition({
      x: event.target.attrs.x,
      y: event.target.attrs.y
    });

    // Also update the line
    const lineRef = areaGroupRef.current?.findOne('Line');
    const linePoints = lineRef.points();
    // console.log('linePoints aa', linePoints);
    const updatedPoints = [
      ...linePoints.slice(0, (id * 2)),
      event.target.attrs.x,
      event.target.attrs.y,
      ...(id !== 0 ? linePoints.slice((id * 2) + 2) : [])
    ];

    // 1,2,[5,6]3,4
    // console.log('updatedPoints', updatedPoints);
    lineRef.points([
      ...linePoints.slice(0, (id * 2)),
      event.target.attrs.x,
      event.target.attrs.y,
      ...linePoints.slice((id * 2) + 2)
    ]);
  };

  const handlePageClick = (event: any, idx: number) => setCurrentPage(idx);

  return (
    <>
      <AppContext.Provider value={context}>
        <Tooltip title={'Show/Hide Bottom Drawer (`)'} placement={'bottomRight'}>
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
            style={{ position: 'absolute', right: 0, bottom: 0, zIndex: 9000 }}
            icon={visible ? <DownOutlined /> : <UpOutlined />}
          />
        </Tooltip>
        <CanvasControls
          setImage={setImage}
          setStageScale={handleStageScale}
          layerRef={layerRef}
          setSelectedShape={context.setSelectedShape}
          colourGroup={colourGroup}
          setColourGroup={setColourGroup}
          toolMode={toolMode}
          setAreaId={setAreaId}
          showHighlightRect={showHighlightRect}
          deleteBufferedLine={deleteBufferedLine}
          helpVisible={helpVisible}
          setHelpVisible={setHelpVisible}
          clearLines={clearLines}
          clearCount={clearCount}
          clearArea={clearArea}
          clearAnnotations={clearAnnotations}
        />
        <div id='workspace' ref={screenshotRef} style={{ width: '100%', height: 'calc(100% - 40px)', display: 'flex' }}>
          <div style={{ background: themeColor, display: 'flex', overflowY: 'scroll', flexDirection: 'column', width: `${pdfPanelWidth}px`, paddingBottom: '10px' }}>
            <span style={{ textAlign: 'center', marginTop: 10 }}>Page {currentPage + 1} / {pages.length}</span>
            {
              pages.map((canvasURL, idx) => <img style={{ objectFit: 'contain', margin: '10px 10px 0', border: `${idx === currentPage ? 'red' : 'lightgrey'} 2px solid`, backgroundColor: 'lightgrey' }} height='150px' onClick={(event: any) => handlePageClick(event, idx)} src={canvasURL} key={idx} />)
            }
          </div>
          {/* </Drawer> */}
          <Drawer
            placement={'bottom'}
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
            key={'bottom'}
            mask={false}
            height={300}
            // closeIcon={
            //   <Tooltip title={'Hide Drawer'} placement={'topRight'}>
            //     <DownOutlined />
            //   </Tooltip>
            // }
            getContainer={(): HTMLElement => document.getElementById('workspace') as HTMLElement}
          >
            <SidePanel
              callibrate={callibrate}
              height={height}
              lines={lines}
              area={areaPoints}
              setHeight={setHeight}
              setWidth={setWidth}
              stage={stage}
              width={width}
              image={image}
              setImage={setImage}
              scale={scale}
              toggleRefresh={toggleRefresh}
              setToggleRefresh={setToggleRefresh}
              count={count}
              groupsRef={groupsRef.current}
              takeScreenshot={getScreenshot}
              screenshotImage={screenshotImage}
              toolMode={toolMode}
            />
          </Drawer>
          <div style={{ border: `10px solid ${themeColor}`, height: '100%', width: `calc(100% - ${pdfPanelWidth}px)`, borderTop: 0, borderLeft: 0, display: 'flex' }}>
            <Stage
              width={width}
              height={height}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              // onContextMenu={handleContextMenu}
              // onClick={handleClick}
              ref={stage}
              draggable={true}
              scaleX={context.stageScale}
              scaleY={context.stageScale}
              onMouseEnter={() => {
                if (!stage.current) {
                  return;
                }

                changeCursor(context.toolMode);
              }}
            >
              <Layer ref={layerRef}>
                <BlueprintImage image={pages[ currentPage ]} />
                <Rect
                  stroke={'black'}
                  dash={[ 2, 2 ]}
                  strokeWidth={3}
                  visible={false}
                  listening={false}
                  ref={selectorRef}
                />
              </Layer>
            </Stage>
          </div>
        </div>
        {/* </div> */}
      </AppContext.Provider>
      <Drawer
        placement={'left'}
        closable={true}
        onClose={() => setHelpVisible(false)}
        visible={helpVisible}
        key={'bottom'}
        mask={true}
        width={400}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center'
        }}>
          <div style={{ padding: '24px' }}>
            <h1>Blueprint Estimation Tool</h1>
            <h2>Instructions</h2>
            <ol style={{ padding: '0 15px' }}>
              <li>
                Upload your desired blueprint by clicking the "Upload Image" button located on the bottom left of the page.
            </li>
              <li>
                Move and set the green calibration (CAL) line to the right length.
            </li>
              <li>
                In the bottom left panel, enter the scale of the calibration line to 3 decimal places (in metres).
            </li>
              <li>
                In the top bar, select a colour group (Red, Orange, Green or Blue)
            </li>
              <li>
                In the top bar, select any of the available modes (Distance, Area, Count modes)
            </li>
              <li>
                Position your mouse crosshair to the desired sections of your blueprint.
            </li>
              <li>
                Left-click with your mouse to start drawing lines/areas/count over your blueprint.
            </li>
              <li>
                There are two ways to go back to "Observe" mode:
              <ul>
                  <li>via clicking the "Observe" button at the top bar.</li>
                  <li>via pressing the ESC key on your keyboard.</li>
                </ul>
              </li>
            </ol>
            <h2>Keyboard shortcuts</h2>
            <ul>
              <li>` - Show/Hide bottom drawer</li>
              <li>Esc - Select "Observe" mode</li>
            </ul>
          </div>
          <div style={{ padding: '0 0 16px' }}>Development: <a href='mailto:rommel.b.vergara@gmail.com'>RV</a></div>
        </div>
      </Drawer>
    </>
  );
};
