// Calculates the rotation given 2 points
export const calcRotation = (pointA: any, pointB: any) => {
  // TOA
  // tan A = oppositeLength / adjacentLength
  const adjacentLength = Math.abs(pointB.x - pointA.x);
  const oppositeLength = Math.abs(pointB.y - pointA.y);
  const initialRot = Math.atan(oppositeLength / adjacentLength) * 180 / Math.PI;

  let rotation = isNaN(initialRot) ? 0 : initialRot;

  /*
          |
     3rd  |   4th
  ________A_____B__
          |
     2nd  |   1st
          |
  */
  if (pointB.x <= pointA.x && pointB.y >= pointA.y) {
    // 2nd quad
    rotation = 180 - rotation;
  } else if (pointB.x <= pointA.x && pointB.y <= pointA.y) {
    // 3rd quad
    rotation = 180 + rotation;
  } else if (pointB.x >= pointA.x && pointB.y <= pointA.y) {
    // 4th quad
    rotation = 360 - rotation;
  }

  return rotation;
};

// Calculates the length of a line given two points
export const calcLineLength = (pointA: any, pointB: any) => Math.sqrt(Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2));

// Area utils
export const AREA_ID_PREFIX = 'area';
export const LINE_ID_PREFIX = 'line';

export const areaFromCoords = (coordArray: any[], scale: number) => {
  // const lineRef = areaPointsRef; //areaGroupRef.current?.findOne('Line');

  if (!coordArray || coordArray.length === 0) {
    return '0';
  }

  const linePoints = coordArray;
  // console.log('asdfasdfa', linePoints);

  const x = [ ...linePoints, linePoints[ 0 ], linePoints[ 1 ] ].map(c => c / scale);
  // const x = [ ...coordArray, coordArray[ 0 ], coordArray[ 1 ] ].map(c => c / scale.current);

  let a = 0;

  // Must have even number of elements
  // if (x.length % 2) {
  //   return;
  // }

  // Process pairs, increment by 2 and stop at length - 2
  for (let i = 0, iLen = x.length - 2; i < iLen; i += 2) {
    a += (x[ i ] * x[ i + 3 ] - x[ i + 2 ] * x[ i + 1 ]);
  }
  return (Math.abs(a / 2)).toFixed(3);
};

export const getPolygonCentroid = (rawPts: any[]) => {
  const pts = rawPts.reduce((acc, curr, index) => index !== rawPts.length - 1 && index % 2 !== 1 ? [
    ...acc,
    ...[ {
      x: rawPts[ index ],
      y: rawPts[ index + 1 ]
    } ]
  ] : acc, []);

  const first = pts[ 0 ],
    last = pts[ pts.length - 1 ];
  if (first.x !== last.x || first.y !== last.y) { pts.push(first); }
  let twicearea = 0;
  let x = 0;
  let y = 0;
  const nPts = pts.length;
  let p1;
  let p2;
  let f;

  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[ i ]; p2 = pts[ j ];
    f = p1.x * p2.y - p2.x * p1.y;
    twicearea += f;
    x += (p1.x + p2.x) * f;
    y += (p1.y + p2.y) * f;
  }
  f = twicearea * 3;
  // f is 0 when we haven't drawn a 2d shape yet
  return f !== 0 ? { x: x / f, y: y / f } : null;
};
