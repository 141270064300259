import {
  DeleteOutlined,
  DownOutlined,
  DragOutlined,
  FullscreenExitOutlined,
  GatewayOutlined,
  LineOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons/lib/icons';
import { Button, Dropdown, Menu, Radio, Select, Tooltip } from 'antd';
import React from 'react';

import { AppContext, ColourGroup, ToolMode } from './AppContext';
import { themeColor } from './colours';
import { AREA_ID_PREFIX } from './lineUtils';

interface ICanvasControls {
  setImage: any;
  setStageScale: any;
  layerRef: any;
  setSelectedShape: any;
  toolMode: any;
  setAreaId: any;
  showHighlightRect: any;
  deleteBufferedLine: any;
  colourGroup: ColourGroup;
  setColourGroup: any;
  helpVisible: boolean;
  setHelpVisible: any;
  clearLines: any;
  clearCount: any;
  clearArea: any;
  clearAnnotations: any;
}

export enum StageScaleType {
  RESET = 'RESET',
  ZOOM_IN = 'ZOOM_IN',
  ZOOM_OUT = 'ZOOM_OUT'
}

export const initialRectangles = [
  {
    x: 25,
    y: 25,
    width: 200,
    height: 5,
    hitStrokeWidth: 30,
    fill: 'green',
    id: 'calibration',
  },
];

export const CanvasControls = (props: ICanvasControls): React.ReactElement => {
  const { toolMode, setToolMode } = React.useContext(AppContext);
  const { calLine } = React.useContext(AppContext);

  const handleDeleteMenuClick = (e: any) => {
    if (e.key === ToolMode.PERIMETER) {
      const drawnLines = props.layerRef.current?.find('Line');
      drawnLines.filter((line: any) => line.parent.attrs.id.startsWith(`line-${props.colourGroup.toLocaleLowerCase()}`)).forEach((line: any) => line.parent.destroy());
      props.layerRef.current?.batchDraw();
      props.showHighlightRect(false);
      props.setSelectedShape(null);
      props.clearLines();
      props.deleteBufferedLine();
    } else if (e.key === ToolMode.AREA) {
      const drawnAreas = props.layerRef.current?.find('Line').filter((l: any) => l.attrs.id.startsWith(`${AREA_ID_PREFIX}-${props.colourGroup.toLocaleLowerCase()}`));
      drawnAreas.forEach((area: any) => area.parent.destroy());
      props.layerRef.current?.batchDraw();
      props.showHighlightRect(false);
      props.setSelectedShape(null);
      props.clearArea();
      props.deleteBufferedLine();
    } else if (e.key === ToolMode.COUNT) {
      const drawnLabels = props.layerRef.current?.find('Label');
      const countLabels = drawnLabels.filter((label: any) => label.attrs.id?.startsWith(`custom-count-label-${props.colourGroup.toLocaleLowerCase()}`));
      countLabels.forEach((label: any) => label.destroy());
      // required to refresh the canvas
      props.layerRef.current?.batchDraw();
      props.clearCount();
    } else {
      // Delete All
      // perimeter
      props.layerRef.current?.find('Line')
        .filter((line: any) => line.parent.attrs.id.startsWith('line-'))
        .forEach((line: any) => line.parent.destroy());

      // areas
      props.layerRef.current?.find('Line')
        .filter((l: any) => l.attrs.id.startsWith(`${AREA_ID_PREFIX}-`))
        .forEach((area: any) => area.parent.destroy());

      props.layerRef.current?.find('Label')
        .filter((label: any) => label.attrs.id?.startsWith('custom-count-label'))
        .forEach((label: any) => label.destroy());

      props.layerRef.current?.batchDraw();

      props.clearAnnotations();
      props.showHighlightRect(false);
      props.setSelectedShape(null);
      props.deleteBufferedLine();
    }
  };

  const handleModeChange = (e: any) => {
    if (e.target.value === ToolMode.PERIMETER) {
      setToolMode(ToolMode.PERIMETER);
      props.toolMode.current = ToolMode.PERIMETER;
    } else if (e.target.value === ToolMode.AREA) {
      setToolMode(ToolMode.AREA);
      props.setAreaId(`${AREA_ID_PREFIX}-${props.colourGroup.toLocaleLowerCase()}-${Date.now()}`);
      props.toolMode.current = ToolMode.AREA;
    } else if (e.target.value === ToolMode.COUNT) {
      setToolMode(ToolMode.COUNT);
      props.toolMode.current = ToolMode.COUNT;
    } else {
      // observe mode
      setToolMode(ToolMode.OBSERVE);
      props.toolMode.current = ToolMode.OBSERVE;
      props.deleteBufferedLine();
    }
  };

  const handleColourChange = (value: any) => {
    // always reset the tool mode if we change groups
    setToolMode(ToolMode.OBSERVE);
    props.toolMode.current = ToolMode.OBSERVE;
    props.deleteBufferedLine();

    props.setColourGroup(value as ColourGroup);
  };

  const deleteMenu = (
    <Menu onClick={handleDeleteMenuClick}>
      <Menu.Item key={ToolMode.PERIMETER}>Distance ({props.colourGroup})</Menu.Item>
      <Menu.Item key={ToolMode.AREA}>Area ({props.colourGroup})</Menu.Item>
      <Menu.Item key={ToolMode.COUNT}>Count ({props.colourGroup})</Menu.Item>
      <Menu.Item key={'ALL'}>All</Menu.Item>
    </Menu>
  );

  const setCalibrationLineText = 'Please set the calibration line first';
  const isCalibrationLineSet = calLine.points?.length === 4;
  const getTooltipText = (title: string) => isCalibrationLineSet ? title : setCalibrationLineText;
  return (
    <div style={{ height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px', backgroundColor: themeColor }}>
      <div style={{ display: 'flex' }}>
        <Tooltip title={'Zoom In'} placement={'topLeft'}>
          <Button
            onClick={() => {
              props.setStageScale(StageScaleType.ZOOM_IN);
            }}
            icon={<ZoomInOutlined />}
          />
        </Tooltip>
        <Tooltip title={'Zoom Reset'}>
          <Button
            onClick={() => {
              props.setStageScale(StageScaleType.RESET);
            }}
            icon={<FullscreenExitOutlined />}
          />
        </Tooltip>
        <Tooltip title={'Zoom Out'}>
          <Button
            onClick={() => {
              props.setStageScale(StageScaleType.ZOOM_OUT);
            }}
            icon={<ZoomOutOutlined />}
          />
        </Tooltip>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ alignSelf: 'center', whiteSpace: 'pre', fontSize: '10px', fontWeight: 900 }}>Group: </span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Select
            defaultValue={ColourGroup.RED}
            onChange={handleColourChange}
            style={{ width: 32 }}
            placeholder={'Select Group'}
            dropdownClassName='select-colour'
            showArrow={false}
          >
            <Select.Option className='option-colour' value={ColourGroup.RED}><div style={{ backgroundColor: 'red', width: '100%', height: '100%' }}>&nbsp;</div></Select.Option>
            <Select.Option className='option-colour' value={ColourGroup.ORANGE}><div style={{ backgroundColor: 'orange', width: '100%', height: '100%' }}>&nbsp;</div></Select.Option>
            <Select.Option className='option-colour' value={ColourGroup.GREEN}><div style={{ backgroundColor: 'green', width: '100%', height: '100%' }}>&nbsp;</div></Select.Option>
            <Select.Option className='option-colour' value={ColourGroup.BLUE}><div style={{ backgroundColor: 'blue', width: '100%', height: '100%' }}>&nbsp;</div></Select.Option>
          </Select>
        </div>
        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
          <Radio.Group disabled={!isCalibrationLineSet} value={toolMode} onChange={handleModeChange} buttonStyle={'solid'}>
            <Tooltip title={getTooltipText('Observe')}><Radio.Button value={ToolMode.OBSERVE}><DragOutlined /> Observe</Radio.Button></Tooltip>
            <Tooltip title={getTooltipText('Distance')}><Radio.Button value={ToolMode.PERIMETER}><LineOutlined /> Distance</Radio.Button></Tooltip>
            <Tooltip title={getTooltipText('Area')}><Radio.Button value={ToolMode.AREA}><GatewayOutlined /> Area</Radio.Button></Tooltip>
            <Tooltip title={getTooltipText('Count')}><Radio.Button value={ToolMode.COUNT}><OrderedListOutlined /> Count</Radio.Button></Tooltip>
          </Radio.Group>
          {/* <span style={{ alignSelf: 'center', whiteSpace: 'pre', fontSize: '10px', fontWeight: 900 }}>Mode</span> */}
        </div>
        <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
          {/* <span style={{ alignSelf: 'center', whiteSpace: 'pre', fontSize: '10px', fontWeight: 900 }}>&nbsp;</span> */}
          <Dropdown overlay={deleteMenu} trigger={'click' as any}>
            <Button>
              <DeleteOutlined /> Delete <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <Tooltip title={'Help'} placement={'topRight'}>
          <Button
            onClick={() => {
              props.setHelpVisible(!props.helpVisible);
            }}
            icon={<QuestionCircleOutlined />}
          />
        </Tooltip>
        {/* <Tooltip title={'Show/Hide Bottom Drawer'} placement={'topLeft'}>
          <Button
            onClick={() => {
              props.setVisible(!props.visible);
            }}
            icon={<MenuOutlined />}
          />
        </Tooltip> */}
      </div>
      {/* <div style={{ display: 'flex' }}>
        <span style={{ alignSelf: 'center', whiteSpace: 'pre' }}>Clear: </span>
        <Button
          onClick={() => {
            const drawnLabels = props.layerRef.current?.find('Label');
            const countLabels = drawnLabels.filter((label: any) => label.attrs.id?.startsWith(`custom-count-label-${props.colourGroup.toLocaleLowerCase()}`));
            countLabels.forEach((label: any) => label.destroy());
            // required to refresh the canvas
            props.layerRef.current?.batchDraw();
            props.clearCount();
          }}
          icon={<OrderedListOutlined />}
        >
          Sum
        </Button>
        <Button
          onClick={() => {
            const drawnLines = props.layerRef.current?.find('Line');
            drawnLines.filter((line: any) => !line.parent.attrs.id.startsWith(AREA_ID_PREFIX)).forEach((line: any) => line.parent.destroy());
            props.layerRef.current?.batchDraw();
            props.showHighlightRect(false);
            props.setSelectedShape(null);
            props.setLines([]);
          }}
          icon={<LineOutlined />}
        >
          Distance
        </Button>
        <Button
          onClick={() => {
            const drawnAreas = props.layerRef.current?.find('Line').filter((l: any) => l.attrs.id.startsWith(AREA_ID_PREFIX));
            drawnAreas.forEach((area: any) => area.parent.destroy());
            props.layerRef.current?.batchDraw();
            props.showHighlightRect(false);
            props.setSelectedShape(null);
            props.setAreaPoints({});
          }}
          icon={<GatewayOutlined />}
        >
          Area
        </Button>
        <Uploader setImage={props.setImage} />
      </div> */}
    </div >);
};
