import React from 'react';

export enum ToolMode {
  CAL = 'CAL',
  AREA = 'AREA',
  COUNT = 'COUNT',
  OBSERVE = 'OBSERVE',
  PERIMETER = 'PERIMETER'
}

export enum ColourGroup {
  RED = 'RED',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE'
}

export const AppContext = React.createContext({
  selectedShape: null,
  setSelectedShape: (shape: any): void => { },
  stageScale: 1,
  setStageScale: (scale: number): void => { },
  calLine: {} as any,
  setCalLine: (line: any): void => { },
  toolMode: ToolMode.OBSERVE,
  setToolMode: (toolMode: ToolMode): void => { },
});
