import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Tooltip, Upload } from 'antd';
import React from 'react';

export const Uploader = ({ setImage, image }: any) => {
  const [ fileList, updateFileList ] = React.useState([]);

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onCustomRequest = ({ onSuccess, onError }: any) => new Promise(((resolve, reject) => {
    const ajaxResponseWasFine = true;
    setTimeout(() => {
      if (ajaxResponseWasFine) {
        resolve(onSuccess('done'));
      } else {
        reject(onError());
      }
    }, 1000);
  }));

  const props = {
    fileList,
    beforeUpload: async (file: any): Promise<void> => {
      if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg') {
        message.info(`${file.name} is a png/jpg/jpeg file`);
        getBase64(file, setImage);
        return;
      }

      if (file.type === 'application/pdf') {
        message.info(`${file.name} is a pdf file`);
        getBase64(file, setImage);
        return;
      }

      message.error(`${file.name} is not supported`);
    },
    onChange: (info: any) => {
      // file.status is empty when beforeUpload return false
      updateFileList(info.fileList.filter((file: any) => !!file.status));
    },
    showUploadList: false,
    customRequest: onCustomRequest
  };

  const isPulsating = image === '/images/empty.png';
  return (
    <Upload {...props}>
      {/* <Tooltip title="Upload Image"> */}
      <Button
        className={isPulsating ? 'pulsing' : ''}
        shape="round"
        icon={<UploadOutlined />}
        block={true}
      >Upload Image</Button>
      {/* </Tooltip> */}
    </Upload>
  );
};
