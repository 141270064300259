import React from 'react';
import { CSVLink } from 'react-csv';

import { themeColor } from './colours';
import { areaFromCoords, calcLineLength } from './lineUtils';
import { Button, Card, Col, InputNumber, List, Row, Select, Tooltip } from 'antd';
import { Uploader } from './Uploader';
import { CameraOutlined, FileImageOutlined, FileTextOutlined, LineOutlined } from '@ant-design/icons';
import { AppContext, ColourGroup, ToolMode } from './AppContext';
import { createFileName } from 'use-react-screenshot';
interface IInputPanel {
  callibrate: number;
  height: number;
  // rectangles: any[];
  setHeight: React.Dispatch<React.SetStateAction<any>>;
  // setRectangles: React.Dispatch<React.SetStateAction<any>>;
  setWidth: React.Dispatch<React.SetStateAction<any>>;
  // setScale: any;
  scale: any;
  stage: React.MutableRefObject<any>;
  width: number;
  image: string;
  setImage: any;
  lines: any[];
  toggleRefresh: boolean;
  setToggleRefresh: any;
  area: { [ id: string ]: any[] };
  count: { [ id: string ]: number };
  groupsRef: any;
  takeScreenshot: any;
  screenshotImage: any;
  toolMode: React.MutableRefObject<ToolMode>;
}

const csvHeaders = [
  { label: 'Group', key: 'group' },
  { label: 'Distance (m)', key: 'distance' },
  { label: 'Area (m2)', key: 'area' },
  { label: 'Count', key: 'count' },
];

// TODO: do deep equal comparison if we want to rerender...
export const SidePanel = (props: IInputPanel) => {
  const { calLine, toolMode, setToolMode } = React.useContext(AppContext);

  const [ currentInputScale, setCurrentInputScale ] = React.useState(0);
  const download = (image: any, { name = 'img', extension = 'png' } = {}) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  React.useEffect(() => {
    if (props.screenshotImage) {
      download(props.screenshotImage, { name: 'blueprint', extension: 'png' });
    }
  }, [ props.screenshotImage ]);

  const linesWithLength = props.lines.map((line: any) => ({
    ...line,
    length: Math.sqrt(Math.pow(line.attrs.points[ 0 ] - line.attrs.points[ 2 ], 2) + Math.pow(line.attrs.points[ 1 ] - line.attrs.points[ 3 ], 2))
  }));

  // total of all groups
  const totalLength = linesWithLength.reduce((acc, curr) => acc + (curr.length / props.scale.current), 0);
  const totalArea = Object.keys(props.area).reduce((acc, curr) => acc + +areaFromCoords(props.area[ curr ], props.scale.current), 0);
  const totalCount = Object.keys(props.count).reduce((acc, curr) => acc + props.count[ curr ], 0);

  // totals
  const calcTotalDistance = (itemMap: any) => Object.keys(itemMap).reduce((acc, curr) =>
    acc + (calcLineLength({ x: itemMap?.[ curr ][ 0 ], y: itemMap?.[ curr ][ 1 ] }, { x: itemMap?.[ curr ][ 2 ], y: itemMap?.[ curr ][ 3 ] }) / props.scale.current), 0
  ).toFixed(3);
  const calcTotalArea = (itemMap: any) => Object.keys(itemMap).reduce((acc, curr) => acc + +areaFromCoords(itemMap?.[ curr ], props.scale.current), 0).toFixed(3);

  // unaggregated values
  const getDistancesByGroup = (group: string) => linesWithLength.filter(l => l.attrs.id.startsWith(`line-${group}`)).sort((a, b) => a < b ? 1 : -1).map(l => (l.length / props.scale.current).toFixed(3));
  const getAreasByGroup = (group: string) => Object.keys(props.groupsRef[ group ].sa).filter(id => id.startsWith(`area-${group}`)).sort((a, b) => a < b ? 1 : -1).map(id => areaFromCoords(props.groupsRef[ group ].sa[ id ], props.scale.current));

  const getDistanceList = (colour: string) => (<List
    size="small"
    header={<div style={{
      background: `${colour}`,
      lineHeight: '16px',
      height: '16px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      padding: '0 5px',
      color: 'white',
      fontWeight: 900
    }}>{calcTotalDistance(props.groupsRef[ `${colour}` ].dis)}</div>}
    bordered={true}
    dataSource={getDistancesByGroup(colour)}
    renderItem={item => <List.Item>{item}</List.Item>}
  />);

  const getAreaList = (colour: string) => (<List
    size="small"
    header={<div style={{
      background: `${colour}`,
      lineHeight: '16px',
      height: '16px',
      textAlign: 'center',
      color: 'white',
      fontWeight: 900
    }}>{calcTotalArea(props.groupsRef[ `${colour}` ].sa)}</div>}
    bordered={true}
    dataSource={getAreasByGroup(colour)}
    renderItem={item => <List.Item>{item}</List.Item>}
  />);

  const getCalButton = () => {
    const isDisabled = calLine.points?.length === 4 || toolMode === ToolMode.CAL || props.image === '/images/empty.png';
    return (
      <Tooltip title={'Set Calibration Line'}>
        <Button
          className={isDisabled ? '' : 'pulsing'}
          onClick={() => {
            setToolMode(ToolMode.CAL);
            props.toolMode.current = ToolMode.CAL;
          }}
          // icon={<LineOutlined />}
          // type={'primary'}
          disabled={isDisabled}
          shape={'round'}
        >
          Set Calibration Line
          </Button>
      </Tooltip>
    );
  };

  const inputDrifted = +(props.callibrate / props.scale.current).toFixed(3) !== currentInputScale;
  const isPulsating = calLine.points?.length === 4 && inputDrifted;
  return (
    <div className='side-panel' style={{
      position: 'absolute',
      width: '100%',
      bottom: 0,
      height: '300px',
      background: themeColor,
      overflowY: 'scroll',
      zIndex: 1,
      fontSize: '11px'
    }}>
      <Row gutter={[ 16, 16 ]}>
        <Col flex={1}>
          {/* <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}> */}
          {/* <Card size="small" title="Actions">
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Uploader setImage={props.setImage} />
                <Button
                  download={'blueprint.png'}
                  disabled={!props.stage.current}
                  href={props.stage.current?.toDataURL({ pixelRatio: 3 })}
                  icon={<FileImageOutlined />}
                  block={true}
                >
                  Download Blueprint
                </Button>
                <CSVLink
                  headers={csvHeaders}
                  data={[ ...linesWithLength.map(r => ({ id: r.attrs.id, distance: r.length / props.scale.current })), { id: 'total', distance: totalLength } ]}
                  filename={'distance-report.csv'}
                >
                  <Button icon={<FileTextOutlined />} block={true}>
                    Download Report (.csv)
                  </Button>
                </CSVLink>
              </div>
            </Card> */}
          <Card size="small" title={'Setup'} style={{ flex: 1 }}>
            {/* <span>Adjust length of green line.</span> */}
            {/* <br /> */}
            <Row>
              <Col>
                <span style={{ textAlign: 'center' }}>1. Upload your blueprint <br />
                (.pdf/.png/.jpg/.jpeg):
                </span>
              </Col>
              {/* <Col><span> : </span></Col> */}
              <Col>
                <Uploader image={props.image} setImage={props.setImage} />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={'calibration-title'}>
                  <span style={{ textAlign: 'center' }}>2. Draw the calibration line<br />
          on your blueprint:</span>
                </div>
              </Col>
              <Col>
                {getCalButton()}
              </Col>
            </Row>
            <Row gutter={[ 16, 16 ]} align={'middle'}>
              <Col>
                3. Enter Scale (in metres):
                </Col>
              <Col>
                {/* <input
                  type="string"
                  onChange={e => {
                    props.scale.current = (+(props.callibrate / parseFloat(e.target.value)).toFixed(3));
                    props.setToggleRefresh(!props.toggleRefresh);
                  }}
                  style={{
                    borderRadius: '16px',
                    padding: '0 5px',
                    textAlign: 'center'
                  }}
                /> */}
                <InputNumber
                  className={isPulsating ? 'pulsing' : ''}
                  min={1}
                  step={0.1}
                  precision={3}
                  defaultValue={1}
                  value={currentInputScale}
                  // value={props.callibrate !== 0 && props.scale.current !== 0 ? props.callibrate / props.scale.current : 0}
                  size={'large'}
                  onChange={(e: any) => {
                    const scale = +(props.callibrate / parseFloat(e)).toFixed(3);
                    props.scale.current = isNaN(scale) ? 1 : scale;
                    const userInput = +parseFloat(e).toFixed(3);
                    setCurrentInputScale(isNaN(userInput) ? 1 : userInput);
                    props.setToggleRefresh(!props.toggleRefresh);
                  }} />
                {inputDrifted && currentInputScale !== 0 && <span style={{ color: 'red' }}>re-enter scale</span>}
              </Col>
            </Row>
            {/* <Row gutter={[ 16, 16 ]} align={'middle'} justify={'space-between'}>
                <Col>
                  Pixel Distance:
                </Col>
                <Col>
                  <input
                    disabled={true}
                    type="string"
                    style={{
                      borderRadius: '16px',
                      padding: '0 5px',
                      textAlign: 'center',
                      background: 'lightgrey'
                    }}
                    value={props.callibrate.toFixed(3)}
                  />
                </Col>
              </Row> */}
            {/* <Row gutter={[ 16, 16 ]} align={'middle'} justify={'space-between'}>
                <Col>
                  Pixels per Metre:
                </Col>
                <Col>
                  <input
                    disabled={true}
                    type="string"
                    style={{
                      borderRadius: '16px',
                      padding: '0 5px',
                      textAlign: 'center',
                      background: 'lightgrey'
                    }}
                    value={props.scale.current}
                  />
                </Col>
              </Row> */}
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              {/* <Tooltip title={'Blueprint Image (.png)'}> */}
              {/* <Button
                  download={'blueprint.png'}
                  disabled={!props.stage.current}
                  href={props.stage.current?.toDataURL({ pixelRatio: 3 })}
                  icon={<FileImageOutlined />}
                  block={true}
                >
                  Download Blueprint
                </Button> */}
              <Button
                onClick={props.takeScreenshot}
                icon={<CameraOutlined />}
                block={true}
              >
                Export Image
                </Button>
              {/* </Tooltip> */}
              <CSVLink
                headers={csvHeaders}
                data={[
                  ...Object.keys(props.groupsRef).map(group => ({
                    group,
                    distance: getDistancesByGroup(group).join('\n'),
                    area: getAreasByGroup(group).join('\n'),
                    count: props.groupsRef[ group ].count
                  })),
                  {
                    group: 'total',
                    distance: totalLength.toFixed(3),
                    area: totalArea.toFixed(3),
                    count: totalCount
                  }
                ]}
                filename={'blueprint-report.csv'}
              >
                {/* <Tooltip title={'Report (.csv)'}> */}
                <Button icon={<FileTextOutlined />} block={true}>
                  Export Excel
                  </Button>
                {/* </Tooltip> */}
              </CSVLink>
            </div>
          </Card>
          {/* </div> */}
        </Col>
        <Col flex={'auto'}>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Card size="small" title="Distance (DIS)" style={{ flex: 4 }} >
              <h4>Total: {totalLength.toFixed(3)} m</h4>
              <Row justify={'center'}>
                <Col flex={'auto'}>
                  {getDistanceList(ColourGroup.RED.toLocaleLowerCase())}
                </Col>
                <Col flex={'auto'}>
                  {getDistanceList(ColourGroup.ORANGE.toLocaleLowerCase())}
                </Col>
                <Col flex={'auto'}>
                  {getDistanceList(ColourGroup.GREEN.toLocaleLowerCase())}
                </Col>
                <Col flex={'auto'}>
                  {getDistanceList(ColourGroup.BLUE.toLocaleLowerCase())}
                </Col>
              </Row>
            </Card>
            <Card size="small" title="Surface Area (SA)" style={{ flex: 4 }} >
              <h4>Total: {totalArea.toFixed(3)} m²</h4>
              <Row justify={'center'}>
                <Col flex={'auto'}>
                  {getAreaList(ColourGroup.RED.toLocaleLowerCase())}
                </Col>
                <Col flex={'auto'}>
                  {getAreaList(ColourGroup.ORANGE.toLocaleLowerCase())}
                </Col>
                <Col flex={'auto'}>
                  {getAreaList(ColourGroup.GREEN.toLocaleLowerCase())}
                </Col>
                <Col flex={'auto'}>
                  {getAreaList(ColourGroup.BLUE.toLocaleLowerCase())}
                </Col>
              </Row>
            </Card>
            <Card size="small" title="Count" style={{ flex: 1 }} >
              <h4>Total: {totalCount}</h4>
              <div style={{ textAlign: 'center', backgroundColor: 'red', fontWeight: 900, color: 'white' }}>{props.count[ 'red' ]}</div>
              <div style={{ textAlign: 'center', backgroundColor: 'orange', fontWeight: 900, color: 'white' }}>{props.count[ 'orange' ]}</div>
              <div style={{ textAlign: 'center', backgroundColor: 'green', fontWeight: 900, color: 'white' }}>{props.count[ 'green' ]}</div>
              <div style={{ textAlign: 'center', backgroundColor: 'blue', fontWeight: 900, color: 'white' }}>{props.count[ 'blue' ]}</div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
